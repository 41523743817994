import React from "react";
import data from "../../Data/main.json";
import ExperienceCard from "./experienceCard";
import ExperienceCardSmol from "./experienceCardSmol";

const Experience = () => {
  const { professional, additional } = data["explorer"]["experience"];
  return (
    <div>
      <span className="text-[1.8rem]">Experience</span>
      {/* Professional Experience Section */}
      <div className="max-h-[60vh] overflow-y-scroll scrollbar">
        <section>
          <span className="text-[1.4rem]">Professional Experience</span>
          <div className="flex flex-row flex-wrap gap-4">
            {professional.map((item) => (
              <ExperienceCard data={item} />
            ))}
          </div>
        </section>
        <section className="mt-4">
          <span className="text-[1.4rem]">Additional Experience</span>
          <div className="flex flex-row flex-wrap gap-2">
            {additional.map((item) => (
              <ExperienceCardSmol data={item} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Experience;
