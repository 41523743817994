// write js function to take a string and return true if it is in a given array of strings

const ENDPOINTS = [
  "home",
  "about",
  "projects",
  "contact",
  "certifications",
  "skills",
  "experience",
  "achievements",
  "socials",
];

export function validateEndpoint(endpoint) {
  return ENDPOINTS.includes(endpoint.toLowerCase());
}
