import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import moment from "moment";
import { BsFillCalendarDateFill } from "react-icons/bs";

const ExperienceCard = ({ data, scale = 1 }) => {
  const { name, position, start, current, location, logo, link, color, end } =
    data;
  const startDate = moment(start, "YYYY-MM-DD");
  const endDate = current ? moment() : moment(current, "YYYY-MM-DD");
  const duration = moment.duration(endDate.diff(startDate));

  function formatDuration(duration) {
    let durationString = "";
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    if (years > 0) {
      durationString += `${years} y `;
    }
    if (months > 0) {
      durationString += `${months} m `;
    }
    if (days > 0) {
      durationString += `${days} d `;
    }

    return durationString.trim();
  }
  return (
    <a
      href={link}
      target="_blank"
      className="max-w-[270px] aspect-[4/3] rounded-2xl flex flex-col bg-white overflow-hidden border-2 border-[#1b1b1b30] relative"
      style={{
        transform: `scale(${scale})`,
      }}
    >
      <div
        className="bg-white border-2 rounded-full px-3 text-sm absolute top-2 right-2"
        style={{
          color,
          borderColor: color,
        }}
      >
        {current ? "Current" : ""}
      </div>
      <div
        className="basis-[65%] flex flex-row justify-center items-center px-2"
        style={{
          color,
        }}
      >
        <div className="w-[90px] aspect-square object-contain my-auto">
          <img
            src={logo}
            className="object-contain my-auto px-3 drop-shadow-xl shadow-2xl"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">{position}</div>
          <div className="text-md">{name}</div>
        </div>
      </div>
      <div
        className="basis-[35%] rounded-b-2xl flex flex-row text-white px-2 w-full justify-between items-center"
        style={{
          background: "linear-gradient(93deg, #181818 -13.05%, #222 119.41%)",
        }}
      >
        <div className="flex flex-col justify-between">
          <div className="flex flex-row basis-[50%] justify-center items-center gap-1">
            <HiLocationMarker />
            <div>{location}</div>
          </div>
          <div className="flex flex-row basis-[50%] justify-center items-center gap-2">
            <BsFillCalendarDateFill />
            <div>
              {start} {current ? "" : " - " + end}
            </div>
          </div>
        </div>
        <div className="flex flex-col text-[#ffffff30] justify-start items-end gap-2 px-2 mt-1">
          <div>{formatDuration(duration)}</div>
        </div>
      </div>
    </a>
  );
};

export default ExperienceCard;
